.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 12px 0;
    /*border-radius: 4px;*/
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 12px 12px;
    margin-left: -1px;
    margin-right: -1px;
    line-height: 1.42857143;
    color: #01A982;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.33);
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    z-index: 2;
    color: #01A982;
    background-color: #fff;
    border-color: #01A982;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    /*border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;*/
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    /*border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;*/
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #01A982;
    border-color: #01A982;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
    color: #777777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: rgba(0,0,0,0.33);
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    /*border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;*/
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    /*border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;*/
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    /*border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;*/
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    /*border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;*/
}