:root {
    --border: #0000005C;
}

.or {
    display: table;
    font-size: .875rem;
    margin: 1rem 0 2rem;
    text-align: center;
    width: 100%;
}

    .or:after, .or:before {
        display: table-cell;
        content: "";
        border-bottom: 1px solid var(--border);
        height: 1px;
        width: 50%;
    }

    .or:after, .or:before {
        display: table-cell;
        content: "";
        border-bottom: 1px solid var(--border);
        height: 1px;
        width: 50%;
    }

    .or span {
        height: 2rem;
        width: 2rem;
        display: inline-block;
        border: 1px solid var(--border);
        border-radius: 50%;
        margin-bottom: -1rem;
        line-height: 1.8rem;
        vertical-align: bottom;
    }
