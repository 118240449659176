

/*.map {
    border: 1px solid #222;
}

    .map .meridian-map-background {
        background: #333;
    }

    .map .meridian-floor-control,
    .map .meridian-tag-control,
    .map .meridian-zoom-button {
        fill: #fff;
        background: #444;
        border-color: #333;
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
    }

        .map .meridian-floor-control:focus,
        .map .meridian-tag-control:focus,
        .map .meridian-zoom-button:focus {
            box-shadow: 0 0 0 3px #0f0, 0 0 3px #0f0;
        }

.meridian-zoom-controls {
    border-radius: 0;
    box-shadow: none;
}

.map .meridian-zoom-button-in {
    margin-bottom: 10px;
}

.map .meridian-floor-control:hover,
.map .meridian-tag-control:hover,
.map .meridian-zoom-button:hover {
    background: #ff0000;
}

.map .meridian-floor-control:active,
.map .meridian-tag-control:active,
.map .meridian-zoom-button:active {
    background: #ff0000;
    
}*/
.map .meridian-floor-control,
.map .meridian-zoom-button,
.map .meridian-tag-control {
    fill: #01A982;

}


.map .meridian-floor-control:focus,
.map .meridian-zoom-button:focus {
    background: white;
    outline: none;
    box-shadow: 0 0 0 1px #01a982 inset;
}

.map .meridian-floor-label {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
    max-width: fit-content;
}
.map .meridian-placemark-type-conference_room {
    background-color: #01a982 !important;
    border-color: #01a982 !important;
    /*background-image: none; */
}
/*.meridian-tag-label-Chair {
    border-color: green;
}*/
.meridian-tag {
    width: 25px !important;
    height: 25px !important;
}
.map .meridian-placemark-type-elevator {
    /*background-color: red;*/
    border-color: red;
    border: 3px solid;
    /*background-image: none; */
}
.meridian--private--qs9yr6-watermark {
    visibility: hidden;
}
/*.meridian--private--16ipwt5-overlay-layer {
    overflow: visible !important;
}*/
svg {
    overflow: visible !important;
}
/*.meridian--private--1l51shd-overlay-search-bar {
    background-color: #01A982;
}
.meridian--private--1e0a3vd-overlay-building-name {
    color: #01A982;
}*/
[class*="overlay-search-bar"] {
    background-color: #01A982 !important;
}
[class*="overlay-search-input"]:focus {
    outline: 0;
    box-shadow: inset 0 0 0 1px hsl(166, 99%, 33%) !important;
}
[class*="overlay-building-name"] {
    color: #01A982 !important;
}
[class*="overlay-floor-button-curent-floor"] {
    color: hsl(166, 99%, 33%) !important;
}
[class*="overlay-floor-button-curent-floor"]:focus {
    box-shadow: inset 0 0 0 1px white, inset 0 0 0 2px hsl(166, 99%, 33%) !important;
    outline: 0;
}
[class*="loading"] > [class*="private"] {
    border-top: 2px #01A982 solid;
}
